import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/project.css";

const Project = (props) => {
	const { logo, logo2, title, description, linkText, link } = props;
	const screenshotUrl = `https://api.microlink.io/?url=${link}&screenshot=true&embed=screenshot.url&size=800x600`;

	return (
		<React.Fragment>
			<div className="project">
				<Link to={link}>
					<div className="project-container">
						<div className="project-screenshot">
							<img src={screenshotUrl} alt={`${title} live preview`} className="screenshot-img" />
						</div>
						<div style={{display:'flex', gap:'20px', alignItems: 'center'}}>
							<div className="project-logo">
								<img src={logo} alt="logo" />
							</div>
							{logo2 && <div className="project-logo">
								<img src={logo2} alt=" " />
							</div>}
						</div>
						<div className="project-title">{title}</div>
						<div className="project-description">{description}</div>
						<div className="project-link">
							<div className="project-link-icon">
								<FontAwesomeIcon icon={faLink} />
							</div>

							<div className="project-link-text">{linkText}</div>
						</div>
					</div>
				</Link>
			</div>
		</React.Fragment>
	);
};

export default Project;
